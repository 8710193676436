<template>
  <div class="table-container">
    <button class="vca-button-small table-header-option" @click="excelExport">
      {{ $t("button.export_excel") }}
    </button>
    <VcaTable
      store="takings"
      :list="pg_list"
      :colspan="8"
      :searchLabel="$t('taking.list.search')"
    >
      <template v-slot:colspan>
        <col width="12%" />
        <col width="12%" />
        <col width="12%" />
        <col width="8%" />
        <col width="4%" />
        <col width="4%" />
        <col width="4%" />
        <col width="1%" />
      </template>
      <template v-slot:desktop-filter>
        <vca-accordion :header="$t('taking.list.filter')">
          <div slot="body">
            <TakingFilter v-model="filter" @reset="resetFilter()" />
          </div>
        </vca-accordion>
      </template>
      <template v-slot:desktop-options> </template>
      <template v-slot:desktop-header>
        <th class="vca-table-cell sortable" @click="sort('name')">
          <label> {{ $t("taking.list.name") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('event.name')">
          <label> {{ $t("taking.list.to_event") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('date_of_taking')">
          <label> {{ $t("taking.list.date_of_taking") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label> {{ $t("taking.list.open") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label> {{ $t("taking.list.wait") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label> {{ $t("taking.list.confirmed") }} &varr;</label>
        </th>
        <th class="vca-table-cell sortable" @click="sort('crew.name')">
          <label> {{ $t("taking.list.crew_name") }} &varr;</label>
        </th>
        <th class="vca-table-cell">
          <label>{{ $t("table.options") }}</label>
        </th>
      </template>
      <template v-slot:desktop-content>
        <tr
          class="vca-table-row"
          @click="setCurrent(res)"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in pg_list"
          :key="index"
        >
          <td class="vca-table-cell">
            <a :href="takingLink(res.id)"> {{ takingName(res) }}</a>
          </td>
          <td class="vca-table-cell">
            <a :href="eventLink(res.event.id)"> {{ res.event.name }}</a>
          </td>
          <td class="vca-table-cell">
            {{ dataOfTaking(res.date_of_taking) }}
          </td>
          <td class="vca-table-cell">
            <label>
              {{ formatMoneyStatus(res.state.open, res.state.no_income) }}
            </label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ formatMoneyStatus(res.state.wait, res.state.no_income) }}
            </label>
          </td>
          <td class="vca-table-cell">
            <label>
              {{ formatMoneyStatus(res.state.confirmed, res.state.no_income) }}
            </label>
          </td>
          <td class="vca-table-cell">
            <label> {{ res.crew.name }} </label>
          </td>

          <td class="vca-table-cell">
            <vca-row>
              <label @click="edit(res.id)"
                ><img
                  class="editable"
                  src="@/assets/icons/edit.png"
                  :title="$t('button.edit')"
                  :alt="$t('button.edit')"
              /></label>
              <label
                class="editable"
                @click="remove(res.id)"
                v-if="showRemove(res)"
                ><img
                  class="editable"
                  src="@/assets/icons/delete.png"
                  :title="$t('button.delete')"
                  :alt="$t('button.delete')"
              /></label>
            </vca-row>
          </td>
        </tr>
      </template>
      <template v-slot:mobile-filter>
        <button class="vca-button" @click="do_filter = true">
          {{ $t("taking.list.filter") }}
        </button>
        <vca-popup
          v-if="do_filter"
          :show="do_filter"
          :title="$t('taking.list.filter')"
          @close="do_filter = false"
        >
          <TakingFilter v-model="filter" />
        </vca-popup>
      </template>
      <template v-slot:mobile-header>
        <div class="sortable" @click="sort('name')">
          <label>{{ $t("taking.list.name") }} &varr;</label>
        </div>
        <div>
          <label>{{ $t("taking.list.amounts") }}</label>
        </div>
      </template>
      <template v-slot:mobile-content>
        <tr
          class="vca-table-row"
          :class="{ last: index + 1 == pg.pageSize }"
          v-for="(res, index) in pg_list"
          :key="index"
        >
          <td class="vca-table-cell">
            <vca-column>
              <vca-row @click="setCurrent(res)">
                <div class="vca-table-index">
                  <a :href="takingLink(res.id)">
                    {{ takingName(res) }}
                  </a>
                </div>
                <div class="vca-table-index">
                  {{ formatMoneyStatus(res.state.open, res.state.no_income) }} /
                  {{ formatMoneyStatus(res.state.wait, res.state.no_income) }} /
                  {{
                    formatMoneyStatus(res.state.confirmed, res.state.no_income)
                  }}
                </div>
              </vca-row>
            </vca-column>
          </td>
        </tr>
      </template>
    </VcaTable>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import VcaTable from "@/components/utils/VcaTable";
import TakingFilter from "@/components/finance/takings/list/TakingFilter";
import * as XLSX from "xlsx";
export default {
  name: "TakingTable",
  components: { VcaTable, TakingFilter },
  data() {
    return {
      store: "takings/pg",
      initial: true,
      do_filter: false,
      currentFilter: {
        type_of_event: [],
        event_end_to: undefined,
        event_end_from: undefined,
      },
      currentTextFilter: "",
      updateCount: 0,
    };
  },
  watch: {
    "pg.currentPage": {
      handler() {
        this.$store.dispatch({ type: `${this.store}/request` });
      },
      deep: true,
    },
    "pg.pageSize": {
      handler(value) {
        this.$store.commit(`${this.store}/pageSize`, value);
        if (this.pg.currentPageSize == 1) {
          this.$store.dispatch({ type: `${this.store}/request` });
        }
      },
      deep: true,
    },
    "pg.filterObject": {
      handler() {
        this.$store.dispatch({
          type: `${this.store}/request`,
          data: { inital: true, abort: true },
        });
      },
      deep: true,
    },
    "pg.filter": {
      handler() {
        this.$store.dispatch({
          type: `${this.store}/request`,
          data: { inital: true, abort: true },
        });
      },
      deep: true,
    },
  },
  created() {
    this.resetFilter();
  },
  computed: {
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      pg_list: "takings/pg/list",
    }),
    emptyFilter() {
      return !this.pg.filter || this.pg.filter == "";
    },
    sameFilter() {
      return (
        Object.entries(this.currentFilter).toString() ===
          Object.entries(this.filter).toString() &&
        this.currentTextFilter == this.pg.filter
      );
    },
  },
  methods: {
    get_ymd_date() {
      let dateNow = new Date(Date.now());
      const month = (dateNow.getMonth() + 1).toString().padStart(2, "0");
      const day = dateNow.getDate().toString().padStart(2, "0");
      return dateNow.getFullYear() + "_" + month + "_" + day;
    },
    excelExport() {
      let columns = [
        this.$t("taking.excel.taking_name"),
        this.$t("taking.excel.taking_date"),
        this.$t("taking.excel.event_name"),
        this.$t("taking.excel.event_type"),
        this.$t("taking.excel.event_from_date"),
        this.$t("taking.excel.event_to_date"),
        this.$t("taking.excel.crew_name"),
        this.$t("taking.excel.taking_money"),
        this.$t("taking.excel.source_value"),
        this.$t("taking.excel.source_comment"),
        this.$t("taking.excel.source_money"),
        this.$t("taking.excel.taking_created"),
        this.$t("taking.excel.taking_id"),
        this.$t("taking.excel.source_id"),
        " ",
      ];

      let data = [];
      let hyperlinks = [];
      const eventUUIDs = new Set();
      this.pg_list.forEach((taking) => {
        let eventName = "-";
        let eventId = undefined;
        let eventType = "-";
        let date_taking = "";
        let eventFromDate = "";
        let eventToDate = "";
        if (taking.event.id !== "") {
          eventId = taking.event.id;
          eventUUIDs.add(eventId);
          eventName = taking.event.name;
          eventType =
            taking.event.type_of_event !== ""
              ? this.$t(
                  "events.eventtypes." + taking.event.type_of_event + ".label"
                )
              : "-";

          eventFromDate = new Date(taking.event.start_at * 1000);
          eventToDate = new Date(taking.event.end_at * 1000);
        }
        if (taking.date_of_taking > 0) {
          date_taking = new Date(taking.date_of_taking * 1000);
        }
        let taking_created = new Date(taking.modified.created * 1000);

        taking.sources.forEach((source) => {
          let sourceValueLbl =
            source.value !== ""
              ? this.$t("taking.sources." + source.value + ".label")
              : "-";

          let entry = [
            taking.name,
            date_taking,
            eventName,
            eventType,
            eventFromDate,
            eventToDate,
            taking.crew.name,
            taking.money.amount / 100,
            sourceValueLbl,
            source.description,
            source.money.amount / 100,
            taking_created,
            taking.id,
            source.id,
            " ", // To hide overflow of sourceID
          ];
          data.push(entry);

          hyperlinks.push([eventId, taking.id]);
        });
      });

      // Ergebniszeile über den Daten
      let ws_data = [
        [
          data.length - 1 + " Einnahmen",
          "",
          eventUUIDs.size + " Events",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "SUMME 2",
          "",
          "",
        ],
        [""],
        columns,
      ].concat(data);
      let filename =
        "VcA_Pool_Finanzen_Einnahmen_" + this.get_ymd_date() + ".xlsx";
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(ws_data);

      // https://docs.sheetjs.com/docs/csf/features/colprops#column-widths
      ws["!cols"] = [
        { wch: 20 }, // A
        { wch: 10 },
        { wch: 20 },
        { wch: 11 },
        { wch: 10 },
        { wch: 10 }, // F
        { wch: 12 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 12 },
        { wch: 10 },
        { wch: 4 },
        { wch: 4 }, // N
      ];

      const COL_OFFSET = 4;

      ws["!autofilter"] = { ref: "A3:N" + (data.length + COL_OFFSET) };

      for (let i = COL_OFFSET; i < data.length + COL_OFFSET; ++i) {
        ws["H" + i].z = "0.00 €";
        ws["K" + i].z = "0.00 €";
        ws["B" + i].z = "dd.mm.yyyy";
        ws["E" + i].z = "dd.mm.yyyy";
        ws["F" + i].z = "dd.mm.yyyy";
        ws["L" + i].z = "dd.mm.yyyy";

        let link_idx = i - COL_OFFSET;
        if (hyperlinks[link_idx][0] !== undefined) {
          ws["C" + i].l = {
            Target:
              "https://pool3.vivaconagua.org/events/" + hyperlinks[link_idx][0],
          };
        }

        if (hyperlinks[link_idx][1] !== undefined) {
          ws["A" + i].l = {
            Target:
              "https://pool3.vivaconagua.org/finances/takings/edit/" +
              hyperlinks[link_idx][1],
          };
        }
      }

      ws["K1"] = {
        f: "SUM(K4:K" + (data.length + COL_OFFSET) + ")",
        z: "0.00 €",
      };

      XLSX.utils.book_append_sheet(wb, ws, "Einnahmen");
      XLSX.writeFile(wb, filename);
    },
    create() {
      this.$router.push({ name: "TakingsAdd" });
    },
    showRemove(value) {
      if (value.event.id === "" && value.deposit_units.length === 0) {
        return true;
      }
      return false;
    },
    incUpdateCount() {
      this.updateCount += 1;
    },
    edit(value) {
      this.$router.push({ path: "/finances/takings/edit/" + value });
    },
    remove(value) {
      if (!confirm(this.$t("taking.delete.confirm"))) {
        return false;
      }
      this.$store
        .dispatch({ type: "takings/deleteByID", data: value })
        .then(() => {
          this.$store.dispatch({
            type: `${this.store}/request`,
            data: { inital: true, abort: true },
          });
        });
    },
    resetFilter() {
      this.filter = { ...this.currentFilter };
    },
    formatMoneyStatus(value, no_income) {
      if (no_income) {
        return this.$t("taking.list.no_income");
      } else {
        return value.amount / 100 + " €";
      }
    },
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("takings/current", null);
      } else {
        this.$store.commit("takings/current", value);
      }
    },
    async sort(col) {
      await this.$store.dispatch({
        type: this.store + "/sort",
        col,
      });
      return;
    },
    dataOfTaking(value) {
      if (value === 0) {
        return "-";
      } else {
        return this.date(value);
      }
    },
  },
};
</script>
